import type { AlgoliaPickupLocation } from "~/types/kippie";

type PickupLocationInfo = {
	address: string;
	city: string;
};

export const getPickupLocationAddress = (
	location: KippieLocation | AlgoliaPickupLocation["address"]
): PickupLocationInfo => {
	const { street, streetNumber, streetNumberAddition, zipCode, city } = location;
	return {
		address: `${street} ${streetNumber}${streetNumberAddition ? ` ${streetNumberAddition}` : ""}`,
		city: `${zipCode} ${city}`
	};
};
